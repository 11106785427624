<div class="w-100 h-100 d-flex">
  <div
    class="w-100 d-flex justify-content-between align-items-baseline border-top border-color-grey"
    [ngClass]="{ 'mb-8px': globalVars.unreadNotifications > 0 }"
  >
    <bottom-bar-mobile-tab class="w-100 h-100 text-center" [link]="'/' + globalVars.RouteNames.BROWSE">
      <div class="d-flex flex-column justify-content-center align-items-center bottom-bar-mobile__text">
        <i-feather name="home" class="feather-medium"></i-feather>
        <div class="mt-5px">{{ "bottom_bar_mobile.home" | transloco }}</div>
      </div>
    </bottom-bar-mobile-tab>

    <bottom-bar-mobile-tab class="w-100 h-100 text-center" [link]="'/' + globalVars.RouteNames.NOTIFICATIONS" (tabClick)="onNavigate('/' + globalVars.RouteNames.NOTIFICATIONS)">
      <div class="d-flex flex-column justify-content-center align-items-center bottom-bar-mobile__text">
        <i-feather
          [name]="globalVars.unreadNotifications > 0 ? 'bell-notification' : 'bell'"
          class="feather-medium"
        ></i-feather>
        <div class="mt-5px">
          {{ "bottom_bar_mobile.notifications" | transloco }}
          <span *ngIf="globalVars.unreadNotifications > 0">
            ({{ globalVars.unreadNotifications > 99 ? "99+" : globalVars.unreadNotifications }})
          </span>
        </div>
      </div>
    </bottom-bar-mobile-tab>

    <bottom-bar-mobile-tab class="w-100 h-100 text-center" [link]="'/' + globalVars.RouteNames.INBOX_PREFIX" (tabClick)="onNavigate('/' + globalVars.RouteNames.INBOX_PREFIX)">
      <div class="d-flex flex-column justify-content-center align-items-center bottom-bar-mobile__text">
        <i-feather name="message-square" class="feather-medium"></i-feather>
        <div class="mt-5px">
          {{ "bottom_bar_mobile.messages" | transloco }}
        </div>
      </div>
    </bottom-bar-mobile-tab>

    <bottom-bar-mobile-tab
      *ngIf="globalVars.loggedInUser"
      class="w-100 h-100 text-center"
      [link]="'/' + globalVars.RouteNames.TRENDS"
    >
      <div class="d-flex flex-column justify-content-center align-items-center bottom-bar-mobile__text">
        <i-feather name="trending-up" class="feather-medium"></i-feather>
        <div class="mt-5px">Trends</div>
      </div>
    </bottom-bar-mobile-tab>

    <bottom-bar-mobile-tab
      *ngIf="globalVars.loggedInUser"
      class="w-100 h-100 text-center"
      [link]="'/' + globalVars.RouteNames.WALLET"
    >
      <div class="d-flex flex-column justify-content-center align-items-center bottom-bar-mobile__text">
        <i-feather name="diamond" class="feather-medium"></i-feather>
        <div class="mt-5px">{{ "bottom_bar_mobile.wallet" | transloco }}</div>
      </div>
    </bottom-bar-mobile-tab>

    <bottom-bar-mobile-tab
      *ngIf="!globalVars.loggedInUser"
      class="w-100 h-100 text-center"
      [link]="'/' + globalVars.RouteNames.CREATORS"
    >
      <div class="d-flex flex-column justify-content-center align-items-center bottom-bar-mobile__text">
        <i-feather name="users" class="feather-medium"></i-feather>
        <div class="mt-5px">{{ "left_bar.buy_creators" | transloco }}</div>
      </div>
    </bottom-bar-mobile-tab>

    <bottom-bar-mobile-tab
      *ngIf="!globalVars.loggedInUser"
      class="w-100 h-100 text-center"
      [link]="'/' + globalVars.RouteNames.UPDATE_PROFILE"
    >
      <div class="d-flex flex-column justify-content-center align-items-center bottom-bar-mobile__text">
        <i-feather name="user" class="feather-medium"></i-feather>
        <div class="mt-5px">{{ "left_bar.my_profile" | transloco }}</div>
      </div>
    </bottom-bar-mobile-tab>
  </div>

  <!-- TODO: on mobile, if you click this, should it prompt you to log in?-->
  <a
    *ngIf="globalVars.loggedInUser && showPostButton"
    class="bottom-bar-mobile__create-post-circle rounded-circle link--unstyled bg-primary"
    (click)="openCreatePostModal()"
  >
    <i-feather name="edit-3"></i-feather>
  </a>
</div>
