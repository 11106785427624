<div *ngIf="this.loggedInUser.CanCreateProfile || this.inTutorial; else elseBlock">
  <div class="d-flex flex-column">
    <div style="flex-grow: 1" id="update-profile-tutorial-holder">
      <simple-center-loader *ngIf="!globalVars.loggedInUser"></simple-center-loader>

      <!-- Create Profile Fee Warning -->
      <div
        *ngIf="
          !inTutorial &&
          !globalVars.loggedInUser?.ProfileEntryResponse &&
          ((!globalVars.loggedInUser?.HasPhoneNumber && !globalVars.loggedInUser?.JumioVerified) ||
            !globalVars.isCompProfileCreation) &&
          globalVars.createProfileFeeNanos > 0
        "
        class="p-15px bg-warning"
      >
        {{ "update_profile.create_a_profile_cost" | transloco }} {{ globalVars.createProfileFeeInDeSo() }} DeSo ≈
        {{ globalVars.createProfileFeeInUsd() }} USD.
        <br />
        {{ "update_profile.this_helps_prevent_spam" | transloco }}
      </div>
      <!-- Form Inputs -->
      <div class="mt-30px font-weight-bold update-profile__avatar-title">{{ "update_profile.avatar" | transloco }}</div>
      <div class="update-profile__holder position-relative">
        <div class="mt-10px position-absolute" style="top: 0px; left: 5px; width: 100px; height: 45px">
          <input
            class="file-upload-button"
            type="file"
            accept="image/png, image/jpeg"
            id="cover-file"
            (change)="_handleFileInput($event.target.files, 'cover')"
            style="
              position: absolute;
              left: 0;
              top: 10px;
              bottom: 0;
              opacity: 0;
              cursor: pointer;
              width: 100px;
              height: 45px;
              padding: 0;
            "
          />
          <button type="file" style="width: 100px; height: 45px; background: var(--text)">
            <i-feather name="refresh-cw"></i-feather>
            Upload Cover Photo
          </button>
          <button
            *ngIf="globalVars.loggedInUser?.ProfileEntryResponse?.ExtraData?.FeaturedImageURL"
            class="mt-10px fc-red"
            style="width: 100px; height: 45px; background: var(--text)"
            (click)="removeExtraDataFields('cover')"
          >
            <i-feather name="trash-2"></i-feather>
            Delete Cover Photo
          </button>
        </div>
        <div
          class="update-profile__avatar"
          [style.background-image]="getCoverImageUrl()"
          style="background-repeat: no-repeat; background-position: center; background-size: cover"
        >
          <div>
            <div *ngIf="profilePicInput && profilePicInput.length > 0">
              <div [style.background-image]="'url(' + getProfileImageToShow() + ')'"></div>

              <div class="update-profile__image" [style.background-image]="'url(' + getProfileImageToShow() + ')'">
                <div
                  *ngIf="!globalVars.loggedInUser?.ProfileEntryResponse?.ExtraData?.NFTProfilePictureUrl"
                  (click)="_resetImage()"
                  class="update-profile__image-delete"
                  style="background: var(--text)"
                >
                  <i-feather name="trash-2"></i-feather>
                </div>
              </div>
            </div>
            <div
              *ngIf="!globalVars.loggedInUser?.ProfileEntryResponse?.ExtraData?.NFTProfilePictureUrl"
              class="mt-10px"
              style="position: relative"
            >
              <input
                class="file-upload-button"
                type="file"
                accept="image/png, image/jpeg"
                id="file"
                (change)="_handleFileInput($event.target.files, 'profile')"
                style="
                  position: absolute;
                  left: 0;
                  top: 10px;
                  bottom: 0;
                  opacity: 0;
                  cursor: pointer;
                  width: 130px;
                  padding: 0;
                "
              />
              <button type="file" style="background: var(--text)">
                <i-feather name="refresh-cw"></i-feather>
                {{ "update_profile.upload_image" | transloco }}
              </button>
            </div>
            <div
              *ngIf="globalVars.loggedInUser?.ProfileEntryResponse?.ExtraData?.NFTProfilePictureUrl"
              class="mt-10px"
              style="position: relative"
            >
              <button (click)="removeExtraDataFields('nft')" style="height: 45px; background: var(--text)">
                <i-feather name="trash-2"></i-feather>
                Remove NFT Profile Picture
              </button>
            </div>
            <div>
              <div
                *ngIf="!profilePicInput || profilePicInput.length === 0"
                [ngClass]="{ 'fc-red': profileUpdateErrors.profilePicError }"
                class="fs-13px text-grey5 mt-5px d-flex justify-content-center"
              >
                {{ "update_profile.no_image_selected" | transloco }}
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="globalVars.loggedInUser"
          class="fs-18px px-15px global__mobile-scrollable-section disable-scrollbars update-profile__text"
        >
          <div class="update-profile__title update-profile__username">
            {{ "update_profile.username" | transloco }}
            <span *ngIf="inTutorial" class="ml-5px fs-18px">*</span>
          </div>
          <div>
            <input
              [(ngModel)]="usernameInput"
              (ngModelChange)="_validateUsername($event)"
              class="form-control fs-15px lh-18px p-10px"
              placeholder="{{ 'update_profile.enter_username' | transloco }}"
            />
            <div
              *ngIf="profileUpdateErrors.usernameError"
              [ngClass]="{ 'fc-red': profileUpdateErrors.usernameError }"
              class="fs-13px font-italic mt-5px"
            >
              {{ "update_profile.please_set_username" | transloco }}
            </div>
          </div>
          <div *ngIf="usernameValidationError" class="fc-error fs-13px ml-5px mt-5px">
            {{ usernameValidationError }}
          </div>
          <div *ngIf="!usernameValidationError && usernameInput?.length > 0" class="fc-success fs-13px ml-5px mt-5px">
            {{ usernameInput }} {{ "update_profile.is_available" | transloco }}
          </div>
          <!--
          <div *ngIf="inTutorial">
            <div class="update-profile__title">
              {{ "update_profile.email" | transloco }}
              <span class="ml-5px fs-18px">*</span>
            </div>
            <div class="form-group">
              <input
                type="email"
                class="form-control fs-15px"
                placeholder="Enter an email address."
                [(ngModel)]="emailAddress"
                (ngModelChange)="_validateEmail($event)"
              />
              <div class="fc-red fs-13px font-italic mt-5px" *ngIf="invalidEmailEntered">
                {{ "update_profile.please_enter_valid_email" | transloco }}
              </div>
            </div>
            <div class="update-profile__title">Subscribe to email notifications?</div>
            <div class="custom-control custom-switch custom-switch-lg">
              <input [(ngModel)]="subscribeToNotifs" type="checkbox" class="custom-control-input" id="buyNowCheckbox" />
              <label class="custom-control-label mt-2px" for="buyNowCheckbox"></label>
            </div>
          </div>
          -->
          <div class="update-profile__title">{{ "update_profile.description" | transloco }}</div>

          <div>
            <textarea [(ngModel)]="descriptionInput" class="fs-15px p-10px w-100" rows="4"></textarea>
            <div
              *ngIf="profileUpdateErrors.descriptionError"
              [ngClass]="{ 'fc-red': profileUpdateErrors.descriptionError }"
              class="fs-13px font-italic"
            >
              {{ "update_profile.description_can_only_be" | transloco }} 180
              {{ "update_profile.characters" | transloco }}
            </div>
          </div>

          <div *ngIf="!inTutorial">
            <div class="update-profile__title">
              {{ "update_profile.founder_reward_percentage" | transloco }}
              <i-feather
                name="info"
                (click)="tooltip.toggle()"
                matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
                [matTooltip]="founderRewardTooltip()"
                #tooltip="matTooltip"
              ></i-feather>
            </div>

            <div class="mt-10px position-relative">
              <input
                [(ngModel)]="founderRewardInput"
                min="0"
                max="100"
                type="number"
                class="form-control"
                [disabled]="globalVars.loggedInUser.UsersWhoHODLYouCount === 0"
              />
              <span class="update-profile__cc-percent-symbol"><i-feather name="percent"></i-feather></span>
              <div
                *ngIf="globalVars.loggedInUser.UsersWhoHODLYouCount === 0"
                class="fc-muted font-italic fs-13px pt-5px"
              >
                * {{ "update_profile.you_must_purchase_own_coin" | transloco }}
              </div>
              <div
                *ngIf="profileUpdateErrors.founderRewardError"
                [ngClass]="{ 'fc-red': profileUpdateErrors.founderRewardError }"
                class="fs-13px font-italic pt-5px"
              >
                {{ "update_profile.please_set_founder" | transloco }}
              </div>
            </div>
            <!--
            <div class="update-profile__title">{{ "update_profile.email" | transloco }}</div>
            <div class="form-group">
              <input
                type="email"
                class="form-control fs-15px"
                placeholder="{{ 'update_profile.enter_emailaddress' | transloco }}"
                [(ngModel)]="emailAddress"
                (ngModelChange)="_validateEmail($event)"
              />
              <div class="fc-red fs-15px" *ngIf="invalidEmailEntered">
                {{ "update_profile.please_enter_valid_email" | transloco }}
              </div>
            </div>
            -->
          </div>

          <div class="w-100 my-30px">
            <a
              (click)="updateProfile()"
              [ngClass]="{ 'btn-loading': updateProfileBeingCalled }"
              class="btn update-profile__update-btn"
            >
              {{ "update_profile.update_profile" | transloco }}
            </a>
            <!-- Bottom spacer for PWA apps-->
            <div *ngIf="globalVars.windowIsPWA() && globalVars.isMobile()" class="mt-20px"></div>
            <div *ngIf="globalVars.isMobile()" class="global__bottom-bar-mobile-height"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #elseBlock>
  <div class="px-15px mt-30px">
    <update-profile-get-starter-deso></update-profile-get-starter-deso>
  </div>
</ng-template>
