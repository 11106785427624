<!--TODO: after submit, redirect the user to the feed, where hopefully his post is
    showing at the top -->

<!--TODO: redirect if user isn't logged in-->
<div
  class="global__top-bar__height d-flex align-items-center justify-content-between w-100 px-15px fs-18px font-weight-bold fc-default border-bottom border-color-grey"
  *ngIf="!inTutorial"
>
  <div class="d-flex align-items-center">
    <top-bar-mobile-navigation-control class="mr-15px d-lg-none d-inline-block"></top-bar-mobile-navigation-control>

    {{ "create_post.create_post" | transloco }}
  </div>
</div>

<div
  class="background-color-white"
  id="tutorial-post-container"
  [ngStyle]="{ border: inTutorial ? '1px solid  #e5e5e5' : '', 'border-radius': inTutorial ? '10px' : 'auto' }"
>
  <div *ngIf="inTutorial" class="border" style="border-radius: 10px 10px 0px 0px">
    <div *ngIf="inTutorial">
      <div class="tutorial-header">
        <div class="d-flex justify-content-between">
          <div class="tutorial-title">{{ "create_post.create_a_post" | transloco }}</div>
          <div class="d-flex align-items-center">
            <div class="fc-secondary fs-15px">{{ "create_post.step" | transloco }} 4/4</div>
            <button class="tutorial-skip-button" (click)="skipPostStep()">{{ "create_post.skip" | transloco }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <feed-create-post
    numberOfRowsInTextArea="4"
    style="overflow-y: scroll"
    (postCreated)="onPostCreated($event)"
    [inTutorial]="inTutorial"
  ></feed-create-post>
</div>
