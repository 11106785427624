import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ProfileEntryResponse, User } from "deso-protocol";
import * as _ from "lodash";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { BackendApiService } from "../../../app/backend-api.service";
import { GlobalVarsService } from "../../../app/global-vars.service";

class AltumbaseLeaderboardResult {
  public_key: string;
  diamonds_received_24h?: number = 0;
  diamonds_received_value_24h?: number = 0;
}

class AltumbaseLeaderboardResponse {
  data: AltumbaseLeaderboardResult[] = [];
  pagination: {
    current_page: number;
    last_page: number;
  };
}

const DeSoLocked = "deso_locked_24h";
const Diamonds = "diamonds_received_24h";

export enum AltumbaseLeaderboardType {
  DeSoLocked = "deso_locked_24h",
  Diamonds = "diamonds_received_24h",
}

export class AltumbaseResponse {
  Profile: ProfileEntryResponse;
  DeSoLockedGained: number = 0;
  DiamondsReceived: number = 0;
  DiamondsReceivedValue: number = 0;
  User: User;
}

export const LeaderboardToDataAttribute = {
  [AltumbaseLeaderboardType.DeSoLocked]: "deso_locked_24h",
  [AltumbaseLeaderboardType.Diamonds]: "diamonds_received_24h",
};

@Injectable({
  providedIn: "root",
})
export class AltumbaseService {
  static altumbaseApiURL = "https://altumbase.com/api";
  static altumbaseRef = "ref=bcl";
  static altumbasePageSize = 20;
  constructor(
    private httpClient: HttpClient,
    private backendApi: BackendApiService,
    private globalVars: GlobalVarsService
  ) {}

  constructAltumbaseURL(
    leaderboardType: string,
    pageIndex: number = 1,
    pageSize: number = AltumbaseService.altumbasePageSize
  ): string {
    return `${AltumbaseService.altumbaseApiURL}/${leaderboardType}?${AltumbaseService.altumbaseRef}&page_size=${pageSize}&page=${pageIndex}`;
  }

  getDiamondsReceivedLeaderboard(): Observable<any> {
    return this.getDiamondsReceivedPage(0);
  }

  getDiamondsReceivedPage(
    pageNumber: number,
    pageSize: number = AltumbaseService.altumbasePageSize,
    skipFilters = false
  ): Observable<any> {
    return this.httpClient
      .get(this.constructAltumbaseURL(AltumbaseLeaderboardType.Diamonds, pageNumber, pageSize))
      .pipe(
        switchMap((res: AltumbaseLeaderboardResponse) => {
          return this.getProfilesForAltumbaseLeaderboard(res, AltumbaseLeaderboardType.Diamonds, skipFilters);
        }),
        catchError((error) => {
          console.error("Error fetching diamonds received data", error);
          return of([]); // Return an empty array or default value in case of error
        })
      );
  }

  getDeSoLockedLeaderboard(): Observable<any> {
    return this.getDeSoLockedPage(0);
  }

  getDeSoLockedPage(
    pageNumber: number,
    pageSize: number = AltumbaseService.altumbasePageSize,
    skipFilters = false
  ): Observable<any> {
    return this.httpClient
      .get(this.constructAltumbaseURL(AltumbaseLeaderboardType.DeSoLocked, pageNumber, pageSize))
      .pipe(
        switchMap((res: AltumbaseLeaderboardResponse) => {
          return this.getProfilesForAltumbaseLeaderboard(res, AltumbaseLeaderboardType.DeSoLocked, skipFilters);
        }),
        catchError((error) => {
          console.error("Error fetching DeSo locked data", error);
          return of([]); // Return an empty array or default value in case of error
        })
      );
  }

  getProfilesForAltumbaseLeaderboard(
    res: AltumbaseLeaderboardResponse,
    leaderboardType: AltumbaseLeaderboardType,
    skipFilters: boolean = false
  ): Observable<AltumbaseResponse[]> {
    const results = res.data.length ? res.data : [{ public_key: '', diamonds_received_24h: 0, diamonds_received_value_24h: 0 }];

    if (results.length === 0) {
      return of([]); // Return an empty array or default value in case no results
    }

    return this.backendApi
      .GetUsersStateless(
        results.map((result) => result.public_key),
        true
      )
      .pipe(
        map((userRes: any) => {
          if (!skipFilters) {
            userRes.UserList = _.filter(userRes.UserList, (o) => o.ProfileEntryResponse && !o.IsGraylisted && !o.IsBlacklisted);
          }

          return userRes.UserList.map((user: User, index: number) => {
            return {
              User: user,
              Profile: user.ProfileEntryResponse,
              DiamondsReceived:
                leaderboardType === AltumbaseLeaderboardType.Diamonds
                  ? results[index]?.[LeaderboardToDataAttribute[leaderboardType]] || 0
                  : null,
              DiamondsReceivedValue:
                leaderboardType === AltumbaseLeaderboardType.Diamonds
                  ? results[index]?.["diamonds_received_value_24h"] || 0
                  : null,
              DeSoLockedGained:
                leaderboardType === AltumbaseLeaderboardType.DeSoLocked
                  ? results[index]?.[LeaderboardToDataAttribute[leaderboardType]] || 0
                  : null,
            };
          });
        }),
        catchError((error) => {
          console.error("Error fetching profiles for Altumbase leaderboard", error);
          return of([]); // Return an empty array or default value in case of error
        })
      );
  }
}
