import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  private graphqlUrl = 'https://graphql-prod.deso.com/graphql';

  constructor(private http: HttpClient) {}

  queryGraphql(query: string, variables: any): Observable<any> {
    return this.http.post<any>(this.graphqlUrl, {
      query,
      variables,
    });
  }
}