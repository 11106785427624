import { Component } from "@angular/core";

@Component({
  selector: "buy-deso-tutorial-page",
  templateUrl: "./buy-deso-tutorial-page.component.html",
  styleUrls: ["./buy-deso-tutorial-page.component.scss"],
})
export class BuyDesoTutorialPageComponent {
  constructor() {}
}
