import { Component, Input } from "@angular/core";
import { GlobalVarsService } from "../global-vars.service";
import { Router, NavigationExtras } from '@angular/router';
import { FeedComponent } from "../feed/feed.component";
import { FeedCreatePostModalComponent } from "../feed/feed-create-post-modal/feed-create-post-modal.component";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "bottom-bar-mobile",
  templateUrl: "./bottom-bar-mobile.component.html",
  styleUrls: ["./bottom-bar-mobile.component.scss"],
})
export class BottomBarMobileComponent {
  @Input() showPostButton = false;
  showcaseTab = FeedComponent.SHOWCASE_TAB;
  globalTab = FeedComponent.GLOBAL_TAB;
  constructor(public globalVars: GlobalVarsService, private modalService: BsModalService, private router: Router) {}

  onNavigate(targetRoute: string, event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
    }
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl(targetRoute);
    });
  }


  openCreatePostModal() {
    this.modalService.show(FeedCreatePostModalComponent, {
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
    });
  }
}
