<div
  class="d-flex flex-column align-items-center justify-content-center loading-container"
  [ngStyle]="{ height: getHeight() }"
  (click)="reload()"
>
  <i *ngIf="isDark" class="fas fa-spinner fa-spin fa-3x fc-secondary spinner-color" style="color: white;"></i>
  <i *ngIf="!isDark" class="fas fa-spinner fa-spin fa-3x fc-secondary spinner-color" style="color: black;"></i>
  <div class="mt-12px fc-secondary" *ngIf="!hideLoadingText">
    <div class="fc-secondary fs-24px text-align-center">
      <div *ngIf="titleLoadingText">{{ titleLoadingText }}</div>
      <div *ngIf="!titleLoadingText">{{ "loader.loading" | transloco }}</div>
    </div>
    <div *ngIf="subtitleLoadingText">
      <div class="fc-secondary fs-15px text-center">{{ subtitleLoadingText }}</div>
    </div>
  </div>
</div>