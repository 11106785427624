<!-- Posts -->
<simple-center-loader [height]="200" *ngIf="isLoading && datasource.adapter.isLoading"></simple-center-loader>
<div class="fs-15px text-grey5 font-weight-bold d-flex flex-column" *ngIf="!isLoading">
  <div
    class="creator-profile-nfts__header d-flex justify-content-between align-items-center"
    [ngClass]="{ 'card-view': cardView }"
  >
    <div class="d-flex align-items-center">
      <div class="d-flex" dropdown (click)="$event.stopPropagation()" container="body" style="align-items: center">
        <button class="creator-profile-nfts__dropdown" role="button" dropdownToggle>
          {{ activeTab }}
          <i-feather name="chevron-down" class="feather-small"></i-feather>
        </button>
        <div
          class="dropdown-menu p-0 bc-dropdown-menu creator-profile-nfts__dropdown-menu background-color-white"
          *dropdownMenu
        >
          <div>
            <a
              class="dropdown-menu-item fs-15px d-block link--unstyled p-10px feed-post__dropdown-menu-item"
              (click)="onActiveTabChange(CreatorProfileNftsComponent.MY_GALLERY)"
            >
              {{ "creator_profile_nfts.gallery" | transloco }}
            </a>
            <a
              *ngIf="this.globalVars.loggedInUser?.PublicKeyBase58Check === this.profile.PublicKeyBase58Check"
              class="dropdown-menu-item fs-15px d-block link--unstyled p-10px feed-post__dropdown-menu-item"
              (click)="onActiveTabChange(CreatorProfileNftsComponent.MY_BIDS)"
            >
              {{ "creator_profile_nfts.my_bids" | transloco }}
            </a>
            <a
              class="dropdown-menu-item fs-15px d-block link--unstyled p-10px feed-post__dropdown-menu-item"
              (click)="onActiveTabChange(CreatorProfileNftsComponent.FOR_SALE)"
            >
              {{ "creator_profile_nfts.for_sale" | transloco }}
            </a>
            <a
              *ngIf="this.globalVars.loggedInUser?.PublicKeyBase58Check === this.profile.PublicKeyBase58Check"
              class="dropdown-menu-item fs-15px d-block link--unstyled p-10px feed-post__dropdown-menu-item"
              (click)="onActiveTabChange(CreatorProfileNftsComponent.TRANSFERABLE)"
            >
              {{ "creator_profile_nfts.transferable" | transloco }}
            </a>
            <a
              *ngIf="this.globalVars.loggedInUser?.PublicKeyBase58Check === this.profile.PublicKeyBase58Check"
              class="dropdown-menu-item fs-15px d-block link--unstyled p-10px feed-post__dropdown-menu-item"
              (click)="onActiveTabChange(CreatorProfileNftsComponent.MY_PENDING_TRANSFERS)"
            >
              {{ "creator_profile_nfts.pending" | transloco }}
            </a>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="d-flex" dropdown (click)="$event.stopPropagation()" container="body" style="align-items: center">
          <button class="creator-profile-nfts__dropdown" role="button" dropdownToggle>
            {{ "creator_profile_nfts.order_by" | transloco }}
            {{
              orderNFTsBy === CreatorProfileNftsComponent.ORDER_PRICE_ASC ||
              orderNFTsBy === CreatorProfileNftsComponent.ORDER_PRICE_DESC
                ? CreatorProfileNftsComponent.ORDER_PRICE
                : orderNFTsBy
            }}
            <i-feather
              *ngIf="
                orderNFTsBy === CreatorProfileNftsComponent.ORDER_PRICE_ASC ||
                orderNFTsBy === CreatorProfileNftsComponent.ORDER_PRICE_DESC
              "
              name="{{ orderNFTsBy === CreatorProfileNftsComponent.ORDER_PRICE_ASC ? 'arrow-up' : 'arrow-down' }}"
              class="feather-small position-relative ml-5px"
              style="top: -2px; right: 0px"
            ></i-feather>
            <i-feather name="chevron-down" class="feather-small dropdown-chevron"></i-feather>
          </button>
          <div class="dropdown-menu p-0 bc-dropdown-menu creator-profile-nfts__dropdown-menu" *dropdownMenu>
            <div>
              <a
                class="dropdown-menu-item fs-12px d-block link--unstyled p-10px feed-post__dropdown-menu-item"
                (click)="updateNFTOrder(CreatorProfileNftsComponent.ORDER_RECENT)"
              >
                {{ "creator_profile_nfts.order_by" | transloco }} {{ CreatorProfileNftsComponent.ORDER_RECENT }}
              </a>
              <a
                class="dropdown-menu-item fs-12px d-block link--unstyled p-10px feed-post__dropdown-menu-item"
                (click)="updateNFTOrder(CreatorProfileNftsComponent.ORDER_POPULAR)"
              >
                {{ "creator_profile_nfts.order_by" | transloco }} {{ CreatorProfileNftsComponent.ORDER_POPULAR }}
              </a>
              <a
                class="dropdown-menu-item fs-12px d-block link--unstyled p-10px feed-post__dropdown-menu-item"
                (click)="updateNFTOrder(CreatorProfileNftsComponent.ORDER_PRICE_DESC)"
              >
                {{ "creator_profile_nfts.order_by" | transloco }} {{ CreatorProfileNftsComponent.ORDER_PRICE }}
                <i-feather name="arrow-down" class="feather-xsmall position-relative" style="top: -2px"></i-feather>
              </a>
              <a
                class="dropdown-menu-item fs-12px d-block link--unstyled p-10px feed-post__dropdown-menu-item"
                (click)="updateNFTOrder(CreatorProfileNftsComponent.ORDER_PRICE_ASC)"
              >
                {{ "creator_profile_nfts.order_by" | transloco }} {{ CreatorProfileNftsComponent.ORDER_PRICE }}
                <i-feather name="arrow-up" class="feather-xsmall position-relative" style="top: -2px"></i-feather>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="
    !showProfileAsReserved && !isLoading && !nftResponse?.length && activeTab === CreatorProfileNftsComponent.FOR_SALE
  "
  class="row no-gutters pt-10px creator-profile-nfts__no-nfts"
>
  <div class="d-flex justify-content-center w-100">
    <div class="circle-div">
      <i-feather name="frame" class="feather-xlarge"></i-feather>
    </div>
  </div>
  <div class="d-flex justify-content-center w-100">
    <span *ngIf="profileBelongsToLoggedInUser()">{{ "creator_profile_nfts.no_nfts_for_sale" | transloco }}</span>
    <span *ngIf="!profileBelongsToLoggedInUser()">
      &#64;{{ profile.Username }} {{ "creator_profile_nfts.on_platform_no_nfts" | transloco }}
    </span>
  </div>
</div>
<div
  *ngIf="
    !showProfileAsReserved && !isLoading && !nftResponse?.length && activeTab === CreatorProfileNftsComponent.MY_GALLERY
  "
  class="row no-gutters pt-10px creator-profile-nfts__no-nfts"
>
  <div class="d-flex justify-content-center w-100">
    <div class="circle-div">
      <i-feather name="frame" class="feather-xlarge"></i-feather>
    </div>
  </div>
  <div class="d-flex justify-content-center w-100">
    <span *ngIf="profileBelongsToLoggedInUser()">{{ "creator_profile_nfts.no_nfts_purchased" | transloco }}</span>
    <span *ngIf="!profileBelongsToLoggedInUser()">
      &#64;{{ profile.Username }} {{ "creator_profile_nfts.no_nfts_acquired" | transloco }}
    </span>
  </div>
</div>
<div
  *ngIf="
    !showProfileAsReserved &&
    !isLoading &&
    !nftResponse?.length &&
    activeTab === CreatorProfileNftsComponent.TRANSFERABLE
  "
  class="row no-gutters pt-10px creator-profile-nfts__no-nfts"
>
  <div class="d-flex justify-content-center w-100">
    <div class="circle-div">
      <i-feather name="frame" class="feather-xlarge"></i-feather>
    </div>
  </div>
  <div class="d-flex justify-content-center w-100">
    <span *ngIf="profileBelongsToLoggedInUser()">{{ "creator_profile_nfts.no_nfts_transferable" | transloco }}</span>
    <span *ngIf="!profileBelongsToLoggedInUser()">
      &#64;{{ profile.Username }} {{ "creator_profile_nfts.no_nfts_transferable_yet" | transloco }}
    </span>
  </div>
</div>
<div
  *ngIf="
    !showProfileAsReserved &&
    !isLoading &&
    !nftResponse?.length &&
    activeTab === CreatorProfileNftsComponent.MY_PENDING_TRANSFERS
  "
  class="row no-gutters pt-10px creator-profile-nfts__no-nfts"
>
  <div class="d-flex justify-content-center w-100">
    <div class="circle-div">
      <i-feather name="frame" class="feather-xlarge"></i-feather>
    </div>
  </div>
  <div class="d-flex justify-content-center w-100">
    <span *ngIf="profileBelongsToLoggedInUser()">{{ "creator_profile_nfts.no_nfts_pending" | transloco }}</span>
    <span *ngIf="!profileBelongsToLoggedInUser()">
      &#64;{{ profile.Username }} {{ "creator_profile_nfts.no_nfts_pending_yet" | transloco }}
    </span>
  </div>
</div>
<div
  *ngIf="!showProfileAsReserved && !isLoading && !myBids?.length && activeTab === CreatorProfileNftsComponent.MY_BIDS"
  class="row no-gutters pt-10px creator-profile-nfts__no-nfts"
>
  <div class="d-flex justify-content-center w-100">
    <div class="circle-div">
      <i-feather name="frame" class="feather-xlarge"></i-feather>
    </div>
  </div>
  <div class="d-flex justify-content-center w-100">
    <span *ngIf="profileBelongsToLoggedInUser()">{{ "creator_profile_nfts.no_bids" | transloco }}</span>
    <span *ngIf="!profileBelongsToLoggedInUser()">
      &#64;{{ profile.Username }} {{ "creator_profile_nfts.no_bids_yet" | transloco }}
    </span>
  </div>
</div>
<div *ngIf="!showProfileAsReserved && (!isLoading || loadingNewSelection)">
  <div *ngIf="!globalVars.hasUserBlockedCreator(profile.PublicKeyBase58Check)">
    <div
      #uiScroll
      *uiScroll="let nftEntry of datasource; let index = index"
      [ngClass]="{
        'card-view': cardView,
        'bid-view': activeTab === CreatorProfileNftsComponent.MY_BIDS,
        'last-item': index === nftResponse?.length - 1,
        'creator-profile-nfts__bid-card':
          activeTab === CreatorProfileNftsComponent.MY_BIDS && nftEntry.PostEntryResponse
      }"
    >
      <feed-post
        *ngIf="nftEntry.PostEntryResponse.ProfileEntryResponse"
        [isNFTListSummary]="!cardView"
        [contentShouldLinkToThread]="true"
        [includePaddingOnPost]="true"
        [post]="nftEntry.PostEntryResponse"
        [afterCommentCreatedCallback]="_prependComment.bind(this, nftEntry.PostEntryResponse, index)"
        [blocked]="globalVars.hasUserBlockedCreator(profile.PublicKeyBase58Check)"
        [showNFTDetails]="true"
        [showExpandedNFTDetails]="false"
        [setBorder]="true"
        [showAvailableSerialNumbers]="true"
        [cardStyle]="true"
        [profilePublicKeyBase58Check]="profile.PublicKeyBase58Check"
        [isForSaleOnly]="activeTab === CreatorProfileNftsComponent.FOR_SALE"
        [acceptNFT]="activeTab === CreatorProfileNftsComponent.MY_PENDING_TRANSFERS"
        [videoPaused]="pauseVideos"
        (userBlocked)="userBlocked()"
        (pauseAllVideos)="pauseAllVideos($event)"
      ></feed-post>
      <div *ngIf="activeTab === CreatorProfileNftsComponent.MY_BIDS && nftEntry.PostEntryResponse">
        <div class="d-flex align-items-center justify-content-between creator-profile__bid-details">
          <div class="d-flex justify-content-start">
            <span
              class="fc-success mr-10px"
              *ngIf="
                nftEntry.HighestBidAmountNanos === nftEntry.BidAmountNanos &&
                nftEntry.BidderBalanceNanos >= nftEntry.BidAmountNanos
              "
            >
              {{ "creator_profile_nfts.highest" | transloco }}
            </span>
            <div
              class="d-flex align-items-center justify-content-center mr-10px"
              [ngStyle]="{
                'margin-left':
                  !(
                    nftEntry.HighestBidAmountNanos === nftEntry.BidAmountNanos &&
                    nftEntry.BidderBalanceNanos >= nftEntry.BidAmountNanos
                  ) && !cardView
                    ? '65px'
                    : '0px'
              }"
            >
              {{ "creator_profile_nfts.serial" | transloco }} #{{ nftEntry.SerialNumber }}
            </div>
            <div class="d-flex align-items-center">
              {{ "creator_profile_nfts.bid" | transloco }}:
              <span class="px-5px">{{ globalVars.nanosToDeSo(nftEntry.BidAmountNanos, 5) }} $DESO</span>
              <span>(~{{ globalVars.nanosToUSD(nftEntry.BidAmountNanos, 2) }})</span>
            </div>
          </div>
          <div class="d-flex cursor-pointer" (click)="cancelBid(nftEntry)">
            <i-feather name="x" class="feather-small"></i-feather>
            <span class="ml-10px">{{ "creator_profile_nfts.cancel" | transloco }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
