<!--
  DESKTOP
  -->
<div *ngIf="!mobile" class="global__desktop">
  <div class="desktop-top-bar">
    <div class="d-flex justify-content-between align-items-center w-100 h-100 desktop-top-bar-width">
      <a
        class="link--unstyled d-flex align-items-center ml-15px"
        (click)="globalVars.isLeftBarMobileOpen = false"
        [routerLink]="'/' + this.globalVars.RouteNames.BROWSE"
        queryParamsHandling="merge"
      >
        <img [src]="environment.node.logoAssetDir + 'camelcase_logo5.svg'" class="deso" style="height: 30px" />
      </a>
      <div class="d-flex align-items-center">
        <div class="position-relative mr-15px" style="width: 343px; height: 48px">
          <search-bar [resultsUnderBar]="true"></search-bar>
        </div>
        <change-account-selector></change-account-selector>
      </div>
    </div>
  </div>
  <div class="d-flex" style="margin-top: 64px">
    <left-bar></left-bar>
    <div class="global__content__flex">
      <div class="global__center__width">
        <div class="global__center__inner">
          <feed></feed>
        </div>
      </div>
      <div class="global__sidebar__width">
        <right-bar-creators class="global__sidebar__fixed disable-scrollbars"></right-bar-creators>
      </div>
    </div>
  </div>
</div>

<!--
  MOBILE
  -->
<div *ngIf="mobile" class="d-lg-none d-flex flex-column h-100">
  <top-bar-mobile-header></top-bar-mobile-header>
  <div class="mt-30px"></div>

  <div style="flex-grow: 1">
    <div *ngIf="!globalVars.loggedInUser" style="height: 30px"></div>
    <feed [isMobile]="true"></feed>
    <div class="global__bottom-bar-mobile-height">&nbsp;</div>
  </div>

  <!-- Bottom spacer for PWA apps-->
  <div *ngIf="globalVars.windowIsPWA() && globalVars.isMobile()" class="mt-20px"></div>

  <!-- The staked posts have a z-index > 0 so we have to put the bottom bar above them. -->
  <bottom-bar-mobile
    [ngClass]="{ 'pwa-bottom-bar-mobile': globalVars.windowIsPWA() && globalVars.isMobile() }"
    class="global__bottom-bar-mobile"
    style="z-index: 10"
    [showPostButton]="true"
  ></bottom-bar-mobile>
</div>
