<div class="w-100" #searchBarRoot id="searchBarRoot" *transloco="let t">
  <!-- Input -->
  <div class="d-flex align-items-center w-100 fs-15px search-bar">
    <div class="input-group search-bar">
      <div class="input-group-prepend">
        <span
          class="input-group-text search-bar__icon"
          [ngStyle]="{
            'border-bottom-left-radius':
              resultsUnderBar && searchText !== '' && (loading || creators.length > 0) ? '0px' : '6px'
          }"
        >
          <i-feather name="search"></i-feather>
        </span>
      </div>
      <input
        [(ngModel)]="searchText"
        (ngModelChange)="_handleSearchTextChange($event)"
        (keyup.arrowup)="_handleArrowKey('UP')"
        (keyup.arrowdown)="_handleArrowKey('DOWN')"
        (keyup.enter)="_handleCreatorSelect(creators[selectedCreatorIndex])"
        (keyup.escape)="_exitSearch()"
        type="text"
        class="form-control shadow-none search-bar__fix-active"
        style="font-size: 16px; padding-left: 0; border-left-color: rgba(0, 0, 0, 0)"
        [ngClass]="{ 'br-12px': !isSearchForUsersToSendDESO }"
        [ngStyle]="{
          'border-bottom-right-radius':
            resultsUnderBar && searchText !== '' && (loading || creators.length > 0) ? '0px' : '6px'
        }"
        placeholder="{{ placeholderText || t('search_bar.search') }}"
        id="searchbar"
        autocomplete="off"
        #searchbar
      />
    </div>
  </div>

  <!-- Results Dropdown -->
  <div
    class="w-100 search-bar__results-dropdown disable-scrollbars"
    *ngIf="searchText !== '' && (creators.length > 0 || loading || showCloutavista)"
  >
    <div *ngIf="loading" class="d-flex justify-content-center fs-15px fc-muted p-5px">
      {{ t("search_bar.loading") }}
    </div>
    <div *ngIf="!loading && creators.length > 0" class="fs-15px">
      <div
        *ngFor="let creator of creators; let ii = index"
        class="d-flex align-items-center"
        [ngClass]="{ 'search-bar__selected-color': creator.Username === creatorSelected }"
        (click)="_handleCreatorSelect(creators[selectedCreatorIndex])"
        (mouseover)="_handleMouseOver(creator.Username, ii)"
      >
        <div
          [ngClass]="{
            nft__avatar: creator?.ExtraData?.NFTProfilePictureUrl
          }"
          class="search-bar__avatar m-10px"
          [avatar]="creator.PublicKeyBase58Check"
          [nftProfileUrl]="creator?.ExtraData?.NFTProfilePictureUrl"
        ></div>
        <div>
          <span class="fc-default">{{ creator.Username || creator.PublicKeyBase58Check }}</span>
          <span
            *ngIf="creator.IsReserved && !creator.IsVerified"
            class="d-inline-block ml-1 cursor-pointer lh-12px fc-muted"
            matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
            [matTooltip]="t('search_bar.reserved_profile')"
          >
            <i class="far fa-clock fa-md align-middle"></i>
          </span>
          <!--
          <span
            *ngIf="creator.IsVerified"
            (click)="tooltip.toggle()"
            class="ml-1 cursor-pointer text-primary"
            matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
            [matTooltip]="'This account is verified'"
            #tooltip="matTooltip"
          >
            <i class="fas fa-check-circle fa-md align-middle"></i>
          </span>
          -->
          <span *ngIf="creator.CoinPriceDeSoNanos">&nbsp;&middot;&nbsp;</span>
        </div>
        <div class="fc-muted" *ngIf="creator.CoinPriceDeSoNanos">
          ~{{ globalVars.nanosToUSD(creator.CoinPriceDeSoNanos, 2) }}
        </div>
      </div>
    </div>
    <!-- DeSo Search Engine -->
    <div class="main cloutavista-button" *ngIf="showCloutavista">
      <a
        index="-2"
        [ngClass]="{ 'search-bar__selected-color': creatorSelected === 'DeSo Search Engine' }"
        (mouseover)="_handleMouseOver('DeSo Search Engine', -2)"
        (mouseout)="_handleMouseOut('DeSo Search Engine', -2)"
        href="https://desosearchengine.com?search={{ searchText }}"
        target="_blank"
      >
        <div class="search-bar__selected-color"></div>
        <p target="_blank" href="https://desosearchengine.com?search={{ searchText }}">
          <i class="fas fa-external-link-alt" aria-hidden="true"></i>
          {{ t("search_bar.search_on_cloutavista") }}
        </p>
      </a>
    </div>
  </div>
</div>
