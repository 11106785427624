<div
  #bottomBarIcon
  class="cursor-pointer py-10px h-100 d-flex justify-content-center align-items-center fc-secondary"
  (click)="tabClick.emit()"
  [routerLink]="[link]"
  [queryParams]="{ stepNum: null }"
  [routerLinkActive]="['fc-blue']"
  queryParamsHandling="merge"
>
  <ng-content></ng-content>
</div>
