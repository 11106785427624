<!-- Available DeSo Box -->
<div class="p-16px">
  <div class="d-flex justify-content-between align-items-center">
    <div class="fc-secondary fs-15px">
      {{ creatorCoinTrade.assetToSellBalance() }} {{ creatorCoinTrade.assetToSellString() }} available&nbsp;
      <span *ngIf="creatorCoinTrade.assetToSellBalanceInUsd()">
        ≈ {{ creatorCoinTrade.assetToSellBalanceInUsd() | currency }} USD
      </span>
    </div>
    <button
      *ngIf="creatorCoinTrade.tradeType === buyTab && !inTutorial"
      class="plain-button fc-default font-weight-bold"
      (click)="_onBuyDeSoClicked()"
    >
      Buy DESO
    </button>
  </div>
</div>
<div class="w-100 border-bottom"></div>
<div class="p-16px">
  <div *ngIf="creatorCoinTrade.tradeType === buyTab" class="d-flex justify-content-between align-items-center">
    We strongly encourage conducting thorough research before purchasing any creator coin. While creator coins offer a valuable means of supporting creators, it is important to note that there is a risk of losing DeSo if a significant holder of the coin sells after your purchase. Utilizing DeSoGuard can be an excellent starting point for your research.
  </div>
  <div *ngIf="creatorCoinTrade.tradeType === buyTab" class="d-flex justify-content-center align-items-center mt-2">
    <button
      class="button primary-button font-weight-bold" style="width: 200px;"
      (click)="_scanWithDeSoGuard(creatorCoinTrade.creatorProfile.Username, creatorCoinTrade.creatorProfile.PublicKeyBase58Check)"
    >
      Scan with DeSoGuard
    </button>
  </div>
  <!-- Transfer Recipient Box -->
  <div
    *ngIf="creatorCoinTrade.isCreatorCoinTransfer()"
    [ngStyle]="{ 'margin-bottom': creatorCoinTrade?.transferRecipient?.value ? '16px' : '32px' }"
  >
    <div class="mint-nft__sub-title mt-0px" style="margin-top: 0px">Recipient</div>

    <div *ngIf="creatorCoinTrade.amount">
      <div class="mt-16px">
        <search-bar
          [isSearchForUsersToSendDESO]="true"
          (creatorToMessage)="_handleCreatorSelectedInSearch($event)"
        ></search-bar>
      </div>
    </div>

    <div class="px-5px mt-16px">
      <simple-profile-card
        [profile]="creatorCoinTrade.transferRecipient.value"
        [singleColumn]="true"
        [hideFollowLink]="true"
        *ngIf="creatorCoinTrade.transferRecipient.value"
      ></simple-profile-card>
    </div>

    <div class="text-danger text-left fs-14px mt-2">
      <div *ngIf="creatorCoinTrade.showUsernameError" class="mt-10px">
        Username "{{ creatorCoinTrade.transferRecipient.value.Username }}" could not be found.
      </div>
      <div *ngIf="creatorCoinTrade.showPubKeyError" class="mt-10px">Please enter a valid username or public key.</div>
      <div *ngIf="creatorCoinTrade.showCannotSendToSelfError" class="mt-10px">
        Please enter a recipient that is not the logged in user.
      </div>
    </div>
  </div>

  <div class="mint-nft__sub-title">Amount</div>
  <div class="d-flex">
    <div class="d-flex" dropdown (click)="$event.stopPropagation()" container="body" style="align-items: center">
      <button *ngIf="creatorCoinTrade.tradeType === buyTab" class="mint-nft__dropdown-btn" role="button" dropdownToggle>
        {{ creatorCoinTrade.selectedCurrency }}
        <i-feather name="chevron-down" class="feather-small"></i-feather>
      </button>
      <div class="dropdown-menu dropdown-menu-right p-0 bc-dropdown-menu place-bid-dropdown" *dropdownMenu>
        <div>
          <a
            *ngFor="let item of this.creatorCoinTrade.currencyConstantsToHumanLabel() | keyvalue"
            class="dropdown-menu-item fs-12px d-block link--unstyled p-10px feed-post__dropdown-menu-item"
            (click)="creatorCoinTrade.selectedCurrency = item.key; _invalidateAndUpdateAmounts()"
          >
            {{ item.key }}
          </a>
        </div>
      </div>
    </div>
    <div class="position-relative w-100">
      <input
        [formControl]="creatorCoinTrade.amount"
        [ngStyle]="{ 'border-radius': creatorCoinTrade.tradeType === buyTab ? '0px 6px 6px 0px' : '16px' }"
        aria-describedby="usd-label"
        class="mint-nft__input"
        type="number"
        min="0"
        placeholder="0"
      />
      <span class="mint-nft__floating-span fc-blue cursor-pointer" (click)="_maxButtonClicked()">Max</span>
      <span *ngIf="creatorCoinTrade.tradeType !== buyTab" class="mint-nft__floating-span" style="right: 80px">
        {{ creatorCoinTrade.creatorProfile.Username }} coin
      </span>
    </div>
  </div>
  <!-- Slippage error box -->
  <div *ngIf="creatorCoinTrade.showSlippageError" class="px-3 mt-3">
    <div class="w-100 border border-color-grey bg-light text-danger">
      <div class="p-3 fs-14px">The price changed while you were waiting to buy. Please reconfirm your purchase.</div>
    </div>
  </div>

  <!-- Errors-->
  <div
    *ngIf="creatorCoinTrade.amount.invalid && (creatorCoinTrade.amount.dirty || creatorCoinTrade.amount.touched)"
    class="text-danger text-left fs-14px mt-2"
  >
    <div *ngIf="creatorCoinTrade.amount.errors.required" class="mt-10px error-container">
      <i-feather name="alert-circle" class="feather-large"></i-feather>
      Amount is required
    </div>

    <div *ngIf="creatorCoinTrade.amount.errors.exclusiveMin" class="mt-10px error-container">
      <i-feather name="alert-circle" class="feather-large"></i-feather>
      Amount must be greater than {{ this._minAmount() | number : "0.0-9" }}
      (to cover fees)
    </div>

    <div *ngIf="creatorCoinTrade.amount.errors.dynamicMax" class="mt-10px error-container d-flex">
      <i-feather name="alert-circle" class="feather-large mr-5px"></i-feather>
      <div *ngIf="this._maxAmount() && this._maxAmount() > 0; else elseBlock">
        Amount must be less than
        {{ this._maxAmount() | number : "0.0-6" }}
        (your balance net of fees)
      </div>
      <ng-template #elseBlock>Amount must be less than your balance plus fees</ng-template>
    </div>

    <div *ngIf="creatorCoinTrade.amount.errors.pattern" class="mt-10px error-container">
      <i-feather name="alert-circle" class="feather-large"></i-feather>
      Amount must be numbers and decimals only
    </div>
  </div>
</div>

<div class="px-3 border-color-grey">
  <div *ngIf="isUpdatingAmounts; else isUpdatingAmountsElseBlock">
    <!-- Loading spinner-->
    <div class="mt-3 spinner-border text-secondary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <ng-template #isUpdatingAmountsElseBlock>
    <div class="fs-14px mt-10px text-grey5">
      <trade-creator-table [displayForCreatorForm]="true" [creatorCoinTrade]="creatorCoinTrade"></trade-creator-table>
    </div>
  </ng-template>

  <!-- Review button -->
  <div class="pb-3 pt-3" *ngIf="_allowPreviewClick() || !inTutorial">
    <button
      class="primary-button"
      [ngClass]="{ 'disabled-button': !_allowPreviewClick() }"
      [disabled]="!_allowPreviewClick()"
      (click)="_onPreviewClicked()"
    >
      Review
    </button>
    <a *ngIf="!inTutorial" class="fc-muted ml-25px" (click)="_closeModal()">Cancel</a>
  </div>
</div>
